import React from 'react'
import logo from '../assets/lsl-logo.svg'

const Logo = () => {
  return (
    <div className="logo">
      <img src={logo} alt="LSL LOGO" />
    </div>
  )
}

export default Logo
