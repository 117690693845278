
import React, { useContext } from 'react';
import 'react-calendar/dist/Calendar.css';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { DateAndTime } from '../App';
import 'react-day-picker/lib/style.css';



const MyCalender = () => {

  const { selectedDate, onChange, avail_date, active } = useContext(DateAndTime);

  return (
      <div className="calender" >
        <h5>Select a Date</h5>
        <a href="#time">
        <Flatpickr
          value={selectedDate}
          onChange={onChange}
          class= "flatpickr-input active"
          
          options={{ 
            enable: avail_date,
            onReady (_, __, fp) {
              fp.calendarContainer.classList.add("open");
            },
            onClose (_, __, fp) {
              fp.calendarContainer.classList.add("open");
            },
            

          }}
        />
        </a>
      </div>
  );
}

export default MyCalender